/**
 * @description 导出通用配置
 */
module.exports = {

  tokenName: 'Authorization',
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'admin-pro-token',
  // token的有效时间
  expires_in: 'expires_in',
  // 用户信息
  userInfo: 'userInfo',
  // token存储位置localStorage sessionStorage cookie
  storage: 'sessionStorage',
  // 加密公钥
  publicKey:
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0RfctMZofmzbXOUzHJHmNsmdi\n' +
    'K99v3ZwoNTt0VH7hpQYt78ccVoTC4F233w5tjro9dIhaq8d76G8U+8MWWTDC7J+i\n' +
    'ZZs/yBy7vZcVC+T6lN94hwX2QS6y0zNleN2dWNfPAdNVwxDMtQqGXJEwAAmvrsr/\n' +
    'o0liO3stx4aTlAVfjwIDAQAB',
  fileType:
    '.doc, .docx, .xls, .xlsx, .pdf, image/*, .rar, zip, .7z, ppt, pptx, .txt',
}
