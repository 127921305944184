import {request} from "@/util/request";

export function login(params) {
    return request({
        url: '/api/Login/GetJwtToken3',
        method: 'GET',
        params
    })
}

export async function dingLogin(params) {
  return request({
      url: '/api/Login/DingLogin',
    method: 'get',
    params,
  })
}