import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Home'),
  },
/*  {
    path: '/',
    name: 'Index',
    redirect: '/index',
    component: () => import('@/views/index'),
  },*/
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index'),
  },
  {
    path: '/ist',
    name: 'IST',
    component: () => import('@/views/ist'),
  },
  {
    path: '/person',
    name: 'Person',
    component: () => import('@/views/person'),
  },
  {
    path: '/cppcc',
    name: 'Cppcc',
    component: () => import('@/views/cppcc'),
  },
  {
    path: '/addApp',
    name: 'AddApp',
    component: () => import('@/views/cppcc/components/addApp'),
  },
  {
    path: '/editApp',
    name: 'EditApp',
    component: () => import('@/views/cppcc/components/editApp'),
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('@/views/cppcc/info'),
  },
  {
    path: '/score',
    name: 'Score',
    component: () => import('@/views/score'),
  },
  {
    path: '/green',
    name: 'Green',
    component: () => import('@/views/green'),
  },
]

const router = new VueRouter({
  routes,
})

//路由导航守卫
router.beforeEach(async (to, from, next) => {
  if(to.path === '/' || to.path === '/green') return next();
  let hasToken = store.getters['user/token']
  if(!hasToken){
    return next('/')
  }
  await store.dispatch('user/getUserInfo')
  next();
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {undefined
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


export default router

