import Vue from 'vue'
import axios from 'axios'
import store from "@/store";
import router from '@/router'
import {
  baseURL,
  contentType,
  messageName,
  requestTimeout,
  statusName,
  successCode
} from '@/config/net.config'
import {tokenName} from '@/config/setting.config'
import { isArray } from '@/util/validate'
import { getRefreshToken } from '@/api/user'
import { setExpressIn, setToken} from '@/util/token'

// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
  ? [...successCode]
  : [...[successCode]]

const handleData = ({ config, data, status, statusText }) => {
  if (data[statusName] === 0) data[statusName] = '0'
  // 若data.code存在，覆盖默认code
  let code = data && data[statusName] ? data[statusName] : status
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.includes(code)) code = 200
  switch (code) {
    case 200:
      return data
    case 401:
      router.push({ path: '/login', replace: true })
  }
  return Promise.reject()
}

/**
 * @description axios初始化
 */

export function request(config) {
  const instance = axios.create({
    baseURL : baseURL,
    timeout: requestTimeout,
    /*headers: {
      'Content-Type': contentType,
    },*/
  })

  instance.interceptors.request.use(config => {
    const token = store.getters['user/token']
    if (token) config.headers[tokenName] = 'Bearer ' + token
    return config
  }, err => {

  })

  instance.interceptors.response.use(
    (response) => {
      let token = store.getters['user/token'] === '' ? null : store.getters['user/token']
      if (token != null) {
        let express_in = store.getters['user/express_in']
        express_in = JSON.parse(express_in)
        //获取token过期时间 和 登录时间
        let time = express_in.time //上次登录时间
        let expires_in1 = express_in.expires_in //有效期
        let begin_time = time + 2400 * 1000 //token过期时间
        let end_time = time + expires_in1 * 1000 //token过期时间
        let now = Date.now()
        if (now < end_time && now > begin_time) {
          if (!window.isReresh) {
            window.isReresh = true
            getRefreshToken({ token: token }).then((res) => {
              if (res.success) {
                window.isReresh = false
                let a = {
                  time: Date.now(),
                  expires_in: res.data.expires_in,
                }
                setExpressIn(JSON.stringify(a))
                setToken(res.data.token)
              }
            })
          }
        } else if (now > begin_time || now < 0) {
          router.push({ path: '/login', replace: true })
        }
      }
      return handleData(response)
    },
    (error) => {
      const { response } = error
      if (response === undefined) {
        return {}
      } else return handleData(response)
    }
  )

  return instance(config)
}

