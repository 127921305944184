import {request} from "@/util/request";

export function getUserInfos(params) {
  return request({
    url: '/api/User/GetUserInfo',
    method: 'get',
    params,
  })
}

export async function getRefreshToken(params) {
  // 刷新token 注意这里用到的service
  return request({
    url: '/api/Login/RefreshToken/RefreshToken',
    method: 'get',
    params,
  })
}
