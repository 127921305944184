import { login, dingLogin } from '@/api/login'
import {
  getUserInfos,
} from '@/api/user'
import {
  getToken,
  getUserInfo,
  getExpressIn,
  setToken,
  setUserInfo,
  setExpressIn,
} from '@/util/token'
import store from '@/store'

const state = () => ({
  token: getToken(),
  express_in: getExpressIn(),
  userInfo: getUserInfo(),
})

const getters = {
  token: (state) => state.token,
  express_in:(state) => state.express_in,
  userInfo: (state) => state.userInfo,
}
const mutations = {
  setToken(state, token) {
    state.token = token;
    setToken(token)
  },
  setExpressIn(state, express_in) {
    state.express_in = express_in
    setExpressIn(express_in)
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    setUserInfo(userInfo)
  },
}
const actions = {
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    const { success, data } = await login(userInfo)
    if (success) {
      if (data.success) {
        const token = data.token
        const express_in = {
          expires_in: data.expires_in,
          time: Date.now(),
        }
        commit('setToken', token);
        commit('setExpressIn', JSON.stringify(express_in))
      }
    } else {
      return Promise.reject()
    }
  },
  /**
   * @description 浙政钉登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async dingLogin({ commit }, userInfo) {
    const { success, data } = await dingLogin({code:userInfo})
    if (success) {
      if (data.success) {
        const token = data.token
        const express_in = {
          expires_in: data.expires_in,
          time: Date.now(),
        }
        commit('setToken', token)
        commit('setExpressIn', JSON.stringify(express_in))
      }
    } else {
      return Promise.reject()
    }
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch }) {
    let token = store.getters['user/token']
    const { data } = await getUserInfos({ Token: token })
    let userInfo = {
      dept_ID: data.dept_ID,
      dept_Name: data.dept_Name,
      hR_ID: data.hR_ID,
      staff_Name: data.staff_Name,
      user_ID: data.user_ID,
      type: data.type,
      dlg_ID: data.dlG_ID,
    }
    if (userInfo) commit('setUserInfo', userInfo)
  },



  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token);
  },
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} express_in
   */
  setExpressIn({ commit }, express_in) {
    commit('setExpressIn', express_in);
  },
  /**
   * @description 设置用户信息
   * @param {*} state
   * @param {*} userInfo
   */
  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo)
  },
}

export default { state, getters, mutations, actions }

